const testimonials = document.querySelectorAll('.testimonial-wrapper');
if (testimonials.length > 0) {
    const move_tape = (slider, tape, direction) => {
        const scroll = {
            min: 0,
            max: Math.ceil(tape.scrollWidth/slider.clientWidth)*slider.clientWidth,
            current: Math.abs(tape.style.marginLeft.replace('px','')),
            amount: slider.clientWidth
        };
        
        let margin = scroll.current
        if (direction == 'left') {
            margin -= scroll.amount;
        } else if (direction == 'right') {
            margin += scroll.amount;
        }

        if (margin < scroll.min) {
            margin = scroll.max - slider.clientWidth
        }
        if (margin >= scroll.max) {
            margin = scroll.min
        }

        tape.style.marginLeft = -margin + 'px';
    }

    testimonials.forEach(testimonial => {
        const slider = testimonial.querySelector('.testimonial-slider');
        const tape = testimonial.querySelector('.testimonial-tape');
        const arrow_left = testimonial.querySelector('.arrow-left');
        const arrow_right = testimonial.querySelector('.arrow-right');

        arrow_left.addEventListener('click', () => move_tape(slider, tape, 'left'))
        arrow_right.addEventListener('click', () => move_tape(slider, tape, 'right'))
    });
}
