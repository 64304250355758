if (document.querySelector('[data-numeric-input]')) {

    const modifyValue = (field, amount) => {
        if (!parseInt(field.value)) {
            field.value = 0
        }
        let newValue = +field.value + amount

        if (field.dataset.min && +field.dataset.min > newValue) {
            newValue = +field.dataset.min
        }
        if (field.dataset.max && +field.dataset.max < newValue) {
            newValue = +field.dataset.max
        }

        field.value = newValue
        field.dispatchEvent(new Event('change', {bubbles: true}))
    }

    const inputs = document.querySelectorAll('[data-numeric-input]')
    for (const input of inputs) {
        const minusBtn = input.querySelector('[data-numeric-input-minus]')
        const plusBtn = input.querySelector('[data-numeric-input-plus]')
        const field = input.querySelector('[data-numeric-input-field]')

        if (minusBtn) {
            minusBtn.addEventListener('click', () => modifyValue(field, -1))
        }
        if (plusBtn) {
            plusBtn.addEventListener('click', () => modifyValue(field, 1))
        }
    }
}
if (document.querySelector('[data-calc]')) {
    const rows =  document.querySelectorAll('[data-calc-row]')
    const superTotal = document.querySelector('[data-calc-supertotal]')
    const hiddenSuperTotal = document.querySelector('[hidden-supertotal]')
    const nextStep = document.querySelector('[calc-next-step]')
    const getTotals = () => {
        let superTotalValue = 0;
        for(const row of rows) {
            let quantity = row.querySelector('[data-quantity]')
            let price = row.querySelector('[data-price]')
            let perMonth = document.querySelector('[data-permonth]')
            let total = row.querySelector('[data-calc-item-total]')
            if(quantity && price) {
                total.innerHTML = (quantity.value * perMonth.value * price.value).toFixed(2);
                superTotalValue += quantity.value * perMonth.value * price.value;
            }
        }
        if(superTotalValue > 0 && nextStep.classList.contains('hidden')) {
            nextStep.classList.remove('hidden')
        }
        hiddenSuperTotal.value = superTotalValue.toFixed(2);
        superTotal.innerHTML = superTotalValue.toFixed(2);
    }
    for(const row of rows) {
        const quantity = row.querySelector('[data-quantity]')
        const perMonth = document.querySelector('[data-permonth]')
        const minusBtns = row.querySelectorAll('[data-numeric-input-minus]')
        const plusBtns = row.querySelectorAll('[data-numeric-input-plus]')
        if(quantity) {
            quantity.addEventListener('keyup', () => getTotals())
        }
        if(perMonth) {
            perMonth.addEventListener('keyup', () => getTotals())
        }
        if(minusBtns && plusBtns) {
            for (const minusBtn of minusBtns) {
                minusBtn.addEventListener('click', () => getTotals())
            }
            for (const plusBtn of plusBtns) {
                plusBtn.addEventListener('click', () => getTotals())
            }
        }
    }
}