const news = document.querySelectorAll('.news-wrapper');
if (news.length > 0) {
    const move_tape = (slider, tape, direction) => {
        const scroll = {
            min: 0,
            max: Math.ceil(tape.scrollWidth/slider.clientWidth)*slider.clientWidth,
            current: Math.abs(tape.style.marginLeft.replace('px','')),
            amount: slider.clientWidth
        };
        
        let margin = scroll.current
        if (direction == 'left') {
            margin -= scroll.amount;
        } else if (direction == 'right') {
            margin += scroll.amount;
        }

        if (margin < scroll.min) {
            margin = scroll.max - slider.clientWidth
        }
        if (margin >= scroll.max) {
            margin = scroll.min
        }

        tape.style.marginLeft = -margin + 'px';
    }

    news.forEach(story => {
        const slider = story.querySelector('.news-slider');
        const tape = story.querySelector('.news-tape');
        const arrow_left = story.querySelector('.arrow-left');
        const arrow_right = story.querySelector('.arrow-right');

        arrow_left.addEventListener('click', () => move_tape(slider, tape, 'left'))
        arrow_right.addEventListener('click', () => move_tape(slider, tape, 'right'))
    });
}
