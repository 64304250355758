if (document.querySelector('#new-customer') || document.querySelector('#laundry-service')) {
    var step = 0;
    const steps = document.querySelectorAll('.step')

    function showError(input, error) {
        if (input.validity.valueMissing) {
          error.textContent = "This field is required";
        } else if (input.validity.typeMismatch && input.type == "email") {
          error.textContent = "This is not a valid email address.";
        } else if (input.validity.tooShort) {
          error.textContent = `Input should be at least ${input.minLength} characters; you entered ${input.value.length}.`;
        }
    }

    const isValid = (all = false) => {
        let fields;
        if(all) {
            fields = document.querySelectorAll('.form-group')
        }
        else {
            fields = steps[step].querySelectorAll('.form-group')
        }
        let valid = true
        for(const field of fields) {
            const input = field.querySelector('input') ? field.querySelector('input') : field.querySelector('textarea');
            const error = field.querySelector('.error')
            if(!input.validity.valid) {
                showError(input, error)
                valid = false
            }
            else {
                error.textContent = ""
            }
        }
        return valid;
    }

    const moveStep = (selectedStep = -1) => {
        if(selectedStep !== -1 || isValid()) {
            if(selectedStep == -1) {
                step++
                selectedStep = step
            }
            else {
                step = selectedStep
            }
            for(const step of steps) {
                if(!step.classList.contains('hidden')) {
                    step.classList.add('hidden')
                }
            }
            steps[selectedStep].classList.remove('hidden')
            window.scroll({
                top: 180,
                left: 0,
                behavior: "smooth",
              });
        }
    }

    const stepEvents = document.querySelectorAll('[step-event]')
    for (const stepEvent of stepEvents) {
        stepEvent.addEventListener('click', () => moveStep())
    }
    if(document.querySelector('[step-0]')) {
        document.querySelector('[step-0]').addEventListener('click', () => moveStep(0))
    }
    if(document.querySelector('[step-1]')) {
        document.querySelector('[step-1]').addEventListener('click', () => moveStep(1))
    }
    if(document.querySelector('[step-2]')) {
        document.querySelector('[step-2]').addEventListener('click', () => moveStep(2))
    }
}